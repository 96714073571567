import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Flex, Link, Text } from 'rebass/styled-components';
import NextLink from 'next/link';
import { END } from 'redux-saga';
import PageLayout from '../../components/layouts/Main/PageLayout';
import { wrapper } from '../../redux/store';
import { getSSRProtocolAndHost, talentNetworkRouteGuard } from '../../helpers/ssrHelpers';
import { talentNetworkIntroCtaClicked } from '../../redux/actions/talentNetworkActions';
import SEO from '../../components/molecules/Seo';
import { isAuthenticatedSelector, makeSelectUserVerified } from '../../redux/selectors/user';
import { organizationsStringSelector } from '../../redux/selectors/network';
import { NetworkSchema } from '../../schema/network';
import { ProtocolSchema } from '../../schema/protocol';
import MarkdownRenderer from '../../components/atoms/MarkdownRenderer';
import TalentNetwork from '../../containers/talentNetwork/talentNetwork';
import { talentOnboardingCompleteStep } from '../../redux/actions/talentOnboardingActions';

const TalentNetworkPage = ({ network, protocol, host }) => {
  const dispatch = useDispatch();
  const isUserVerified = useSelector(makeSelectUserVerified({ network }));
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const organizationString = organizationsStringSelector(true, network);
  const {
    name: networkName,
    tnSettings = {},
    companiesCount,
    hasTalentNetworkV2: showJobboardsignupv2,
    hasTalentNetwork,
  } = network;
  const { tnTabIntroductionText } = tnSettings;
  const showJobboardsignupv1 = hasTalentNetwork && !showJobboardsignupv2;

  const onCtaClick = () => {
    dispatch(talentOnboardingCompleteStep({ eventName: 'join', network, origin: 'talent_network_tab' }));
  };

  return (
    <>
      <SEO network={network} title="Talent network" host={host} protocol={protocol} />
      {isAuthenticated && showJobboardsignupv1 && (
        <Flex justifyContent="center">
          <Card display="flex" flexDirection="column" mb={4} mt={4} px={4} py={4} width={[1, 1, 1 / 2]}>
            <Text as="h3" mx={0} mb={4} mt={0} color="black" fontSize={3} fontWeight={400}>
              Thanks for putting your career interests on our radar
            </Text>

            <Text as="p" variant="default">
              Here are some pro tips to maximize your access to our network:
            </Text>

            <Text as="p" fontWeight="bold" mb={3} mt={4} variant="default">
              Keep your profile updated
            </Text>

            <Text as="p" mb={3} sx={{ lineHeight: 1.4 }} variant="default">
              Career interests are ever-evolving. Make sure to reflect those changes in your profile so you only get the
              most relevant information from us.
            </Text>

            <Text as="p" mb={3} variant="default">
              <NextLink passHref href="/extended-profile" legacyBehavior>
                <Link className="theme_only" as="a" color="primary" href="/extended-profile">
                  Edit your profile.
                </Link>
              </NextLink>
            </Text>

            <Text as="p" fontWeight="bold" mb={3} mt={4} variant="default">
              Create lists of favorite jobs and {organizationString}
            </Text>

            <Text as="p" mb={3} sx={{ lineHeight: 1.4 }} variant="default">
              Use these lists to stay current on job activity in our network. We will also use this information to learn
              what opportunities are most relevant to you.
            </Text>

            <Text as="p" mb={3} variant="default">
              <NextLink passHref href="/" legacyBehavior>
                <Link className="theme_only" as="a" color="primary" href="/">
                  View jobs.
                </Link>
              </NextLink>
            </Text>

            {isUserVerified && (
              <>
                <Text as="p" fontWeight="bold" mb={3} mt={4} variant="default">
                  Request introductions
                </Text>

                <Text as="p" mb={3} sx={{ lineHeight: 1.4 }} variant="default">
                  You are a verified member of our network. This means you can request introductions directly to
                  {organizationString} that you want to connect with
                </Text>

                <Text as="p" mb={3} variant="default">
                  <NextLink passHref href="/companies" legacyBehavior>
                    <Link className="theme_only" as="a" color="primary" href="/companies">
                      Discover {organizationString}.
                    </Link>
                  </NextLink>
                </Text>
              </>
            )}
          </Card>
        </Flex>
      )}
      {!isAuthenticated && showJobboardsignupv1 && (
        <Flex flexDirection="column" sx={{ '@media (min-width: 640px)': { flexDirection: 'row' } }}>
          <Box pr={4} width={[1, 2 / 3, 2 / 3]} fontSize={3} fontWeight="default" lineHeight="1.4">
            <Text as="h2" mx={0} mb={3} mt={4} color="black" fontSize={6} fontWeight="bold">
              {`${networkName} Talent network`}
            </Text>

            {tnTabIntroductionText && <MarkdownRenderer source={tnTabIntroductionText} />}
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]}>
            <Card
              alignItems="center"
              display="flex"
              flexDirection="column"
              mb={4}
              mt={4}
              px={4}
              py={4}
              textAlign="center"
            >
              <Text as="h2" mx={0} mb={3} mt={0} color="black" fontSize={6} fontWeight={400}>
                Tell us about you
              </Text>

              <Text fontSize={3} mb={3} sx={{ lineHeight: 1.4 }}>
                {companiesCount.toLocaleString('en-US')} {organizationString} use our network as a trusted source to
                hire top talent.
              </Text>

              <Text fontSize={3} mb={4} sx={{ lineHeight: 1.4 }}>
                Share more about the type of opportunity you want next exclusively with our network.
              </Text>

              <NextLink href="/onboarding" legacyBehavior>
                <Button
                  variant="primary"
                  as="a"
                  className="theme_only"
                  onClick={() => dispatch(talentNetworkIntroCtaClicked({ network }))}
                  data-testid="intro-cta"
                  fontSize={3}
                  fontWeight="medium"
                  px={4}
                >
                  Sign up
                </Button>
              </NextLink>

              <Text fontSize={1} mb={4} mt={4} sx={{ lineHeight: 1.4 }}>
                We&apos;ll keep you updated on new opportunities and put your profile in front of the people responsible
                for hiring at our {organizationString}.
              </Text>

              <Text fontSize={1} mb={0} mt={0}>
                Do you already have an account?{' '}
                <NextLink passHref href="/login?redirect=/talent-network" legacyBehavior>
                  <Button
                    display="inline-block"
                    variant="anchorLink"
                    as="a"
                    className="theme_only"
                    href="/login?redirect=/talent-network"
                  >
                    Log in here.
                  </Button>
                </NextLink>
              </Text>
            </Card>
          </Box>
        </Flex>
      )}

      {showJobboardsignupv2 && <TalentNetwork name={networkName} tnSettings={tnSettings} onJoin={onCtaClick} />}
    </>
  );
};

TalentNetworkPage.propTypes = {
  protocol: ProtocolSchema.isRequired,
  host: PropTypes.string.isRequired,
  network: NetworkSchema.isRequired,
};

TalentNetworkPage.Layout = PageLayout;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { protocol, host } = getSSRProtocolAndHost(ctx);
  const { req } = ctx;
  const { network } = req;

  store.dispatch(END);
  await store.sagaTask.toPromise();

  return (
    talentNetworkRouteGuard(network.hasTalentNetwork || network.hasTalentNetworkV2) || { props: { protocol, host } }
  );
});

export default TalentNetworkPage;
