import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import NextLink from 'next/link';
import { Box, Flex, Text } from 'rebass/styled-components';
import MarkdownRenderer from '../../components/atoms/MarkdownRenderer';

const howItWorks = [
  {
    title: 'Join',
    description: 'Tell us about your ideal job.',
  },
  {
    title: 'Match',
    description: 'Get recommended to companies.',
  },
  {
    title: 'Get introduced',
    description: "If there's a mutual fit, we'll make a warm introduction.",
  },
];

const TalentNetwork = ({ name, tnSettings, onJoin }) => (
  <Flex
    m={['80px auto 18px', '120px auto']}
    mw="1024px"
    width="100%"
    sx={{ gap: ['120px', '40px'] }}
    justifyContent={['center', 'center', 'space-between']}
    flexWrap="wrap"
  >
    <Box maxWidth="500px" width="100%">
      <Text
        as="h4"
        mb="8px"
        color="text.subtle"
        fontWeight="500"
        fontSize="12px"
        lineHeight="16.8px"
        letterSpacing="1px"
        sx={{ textTransform: 'uppercase' }}
      >
        {name} TALENT NETWORK
      </Text>
      <Text as="h3" mb="32px" fontWeight="600" fontSize="28px" lineHeight="33px" color="text.dark">
        {tnSettings.tnV2TabIntroductionTitle ? tnSettings.tnV2TabIntroductionTitle : 'Find your next opportunity.'}
      </Text>
      {tnSettings.tnV2TabIntroductionText && (
        <Text
          data-testid="tn-text"
          mb="32px"
          sx={{
            'li:not(:last-child)': {
              marginBottom: 2,
            },
            p: {
              color: 'text.main',
              lineHeight: '26px',
              fontSize: '18px',
            },
            h1: {
              color: 'text.dark',
              fontSize: '24px',
            },
            h2: {
              color: 'text.dark',
              fontSize: '18px',
              marginTop: 4,
            },
          }}
        >
          <MarkdownRenderer source={tnSettings.tnV2TabIntroductionText} />
        </Text>
      )}
      {!tnSettings.tnV2TabIntroductionText && (
        <>
          <Text mb="32px" color="text.main" lineHeight="26px" fontSize="18px">
            Get connected to the right company at the right time.
          </Text>
        </>
      )}
      <Box data-testid="tn-join" onClick={onJoin}>
        <NextLink passHref href="/onboarding" legacyBehavior>
          <Button
            size="large"
            display="inline-flex"
            as="a"
            className="theme_only"
            href="/login?redirect=/talent-network"
          >
            Join now
          </Button>
        </NextLink>
        <Text mt="8px" as="p" color="text.subtle" fontSize="14px" lineHeight="1.4">
          It takes just 3 minutes
        </Text>
      </Box>
    </Box>
    <Box maxWidth="440px" width="100%">
      <Box as="h4" mb="32px" fontWeight="700" fontSize="22px" lineHeight="26px" color="text.dark">
        How it works
      </Box>

      <Flex flexDirection="column" sx={{ gap: '16px' }}>
        {howItWorks.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flex sx={{ gap: '24px' }} key={`how_it_works_${index}`}>
            <Flex flexDirection="column" alignItems="center" sx={{ gap: '16px' }}>
              <Flex
                alignItems="center"
                justifyContent="center"
                height="40px"
                width="40px"
                borderRadius="100%"
                sx={{ border: '2px solid', borderColor: 'neutral.100', borderRadius: '100%' }}
              >
                <Text as="p" color="neutral.700" fontWeight="700" fontSize="18px" lineHeight="20px">
                  {index + 1}
                </Text>
              </Flex>
              {index < howItWorks.length - 1 && (
                <svg width="16" height="41" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 1C9 0.447715 8.55228 2.41411e-08 8 0C7.44772 -2.41411e-08 7 0.447715 7 1L9 1ZM7.29289 40.7071C7.68342 41.0976 8.31658 41.0976 8.70711 40.7071L15.0711 34.3431C15.4616 33.9526 15.4616 33.3195 15.0711 32.9289C14.6805 32.5384 14.0474 32.5384 13.6569 32.9289L8 38.5858L2.34314 32.9289C1.95262 32.5384 1.31946 32.5384 0.928931 32.9289C0.538406 33.3195 0.538406 33.9526 0.928931 34.3431L7.29289 40.7071ZM7 1L7 40L9 40L9 1L7 1Z"
                    fill="#DEE4ED"
                  />
                </svg>
              )}
            </Flex>
            <Box width={['100%', '370px']}>
              <Text as="h4" fontWeight="600" fontSize="18px" lineHeight="22px" mb="8px" color="text.dark">
                {item.title}
              </Text>
              <Text fontSize="18px" lineHeight="1.2" as="p" color="text.main">
                {item.description}
              </Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  </Flex>
);

TalentNetwork.propTypes = {
  name: PropTypes.string.isRequired,
  tnSettings: PropTypes.object.isRequired,
  onJoin: PropTypes.func.isRequired,
};

export default TalentNetwork;
